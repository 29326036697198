<template>
  <div class="research-form">
    <form @submit.prevent="() => {}">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="$l.get('research-form.name-field', 'capitalize')"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="research.name"
        />
        <vs-input
          :label-placeholder="
            $l.get('research-form.samples-required-field', 'capitalize')
          "
          :danger="isSamplesRequiredInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="research.samplesRequired"
          type="number"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.public-data") }}</h3>
        </div>
        <div style="margin: 15px">
          <div v-if="research.figure" style="text-align: center;">
            <img :src="research.figure.figure" style="height: 128px" />
            <vs-button
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-trash"
              style="margin: auto; width: 150px !important"
              @click="onRemoveFigureClick()"
            ></vs-button>
          </div>
          <div v-else>
            <vs-upload
              :action="uploadAddress"
              :headers="uploadHeaders"
              fileName="figure"
              :limit="1"
              :show-upload-button="true"
              :automatic="true"
              accept=".jpg, .png"
              :text="$l.get('behaviour-form.input-label-figure', 'upper')"
              @on-success="onUploadSuccess"
              @on-fail="onUploadFail"
            />
          </div>
        </div>
        <div style="margin-bottom: 50px; margin-left: 15px; margin-top: 15px;">
          <vs-checkbox v-model="research.isPublished" color="success">{{
            $l.get("research-form.is-published-field")
          }}</vs-checkbox>
        </div>
        <vs-input
          :label-placeholder="
            $l.get('research-form.title-field', 'capitalize') + ' (en-US)'
          "
          :danger="isTitleEnUsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="research.titleEnUs"
        />
        <vs-input
          :label-placeholder="
            $l.get('research-form.title-field', 'capitalize') + ' (pt-BR)'
          "
          :danger="isTitlePtBrInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="research.titlePtBr"
        />
        <vs-input
          :label-placeholder="
            $l.get('research-form.title-field', 'capitalize') + ' (es-ES)'
          "
          :danger="isTitleEsEsInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="research.titleEsEs"
        />
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3 style="color: #FFA500">
            {{ $l.get("dictionary.wejoy-diagnostics") }}
          </h3>
        </div>
        <div v-if="research.dataSource">
          <p>{{ research.dataSource.value }}</p>
          <h3>{{ research.dataSource.label }}</h3>
          <div v-if="!research.id">
            <br />
            <vs-button
              color="danger"
              type="border"
              style="width: 100% !important"
              icon-pack="feather"
              icon="icon-trash"
              @click="onRemoveDataSourceClick"
              >{{ $l.get("dictionary.remove") }}</vs-button
            >
          </div>
        </div>
        <div v-else>
          <vue-simple-suggest
            @select="onDiagnosisSelect"
            :list="diagnostics"
            display-attribute="title"
            value-attribute="id"
            :filter-by-query="true"
          ></vue-simple-suggest>
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.phase") }} #1</h3>
        </div>
        <p>
          {{ $l.get("research-form.r-field") }}: ({{ slider1[0] / 100 }},
          {{ slider1[1] / 100 }})
        </p>
        <vs-slider ticks step="1" :min="-100" :max="100" v-model="slider1" />
        <br />
        <p>{{ $l.get("research-form.global-field") }}: ({{ slider2 / 100 }})</p>
        <vs-slider ticks step="1" :min="70" :max="100" v-model="slider2" />
        <div class="centerx">
          <vs-input-number
            v-model="research.samplesMin"
            min="1"
            :label="$l.get('research-form.samples-min-field') + ':'"
          />
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.phase") }} #2</h3>
        </div>
        <p>{{ $l.get("research-form.phase-2-field") }}: ({{ slider3 }}%)</p>
        <vs-slider ticks step="1" :min="0" :max="100" v-model="slider3" />
      </vs-card>

      <vs-card v-if="areas.length > 0" style="margin-top: 10px">
        <div slot="header">
          <h3>{{ $l.get("research-form.delimiters-field") }}</h3>
        </div>
        <vs-tabs v-model="delimitersTabIndex">
          <vs-tab :label="$l.get('dictionary.all')">
            <vs-table :data="research.p3Data.delimiters">
              <template slot="thead">
                <vs-th>{{ $l.get("dictionary.name") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.remove") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="idx" v-for="(tr, idx) in data">
                  <vs-td :data="tr.name">{{ tr.name }}</vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="edit"
                      @click="onEditDelimiterClick(idx, tr)"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="delete"
                      @click="onRemoveDelimiterClick(idx)"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>

          <vs-tab :label="delimiterFormTab">
            <vs-input
              :label-placeholder="
                $l.get('research-form.name-field', 'capitalize')
              "
              :danger="isDelimiterNameInvalid"
              :danger-text="$l.get('errors.input-required', 'capitalize')"
              v-model="delimiterActive.name"
            />
            <div
              v-for="(delimiterValue, index) in delimiterActive.values"
              :key="index"
              style="margin-top: 15px"
            >
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="5"
                >
                  <vs-select
                    v-model="delimiterValue[0]"
                    class="w-full select-large"
                    :label="$l.get('research-details.area-field')"
                  >
                    <vs-select-item
                      :key="index"
                      :value="area.id"
                      :text="area.title"
                      v-for="(area, index) in areas"
                      class="w-full"
                    />
                  </vs-select>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                ></vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="5"
                >
                  <vs-select
                    v-model="delimiterValue[1]"
                    class="w-full select-large"
                    :label="$l.get('research-details.emotion-field')"
                  >
                    <vs-select-item
                      :value="1"
                      :text="emotionLabel(1)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="2"
                      :text="emotionLabel(2)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="3"
                      :text="emotionLabel(3)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="4"
                      :text="emotionLabel(4)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="5"
                      :text="emotionLabel(5)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="6"
                      :text="emotionLabel(6)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="7"
                      :text="emotionLabel(7)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="8"
                      :text="emotionLabel(8)"
                      class="w-full"
                    />
                    <vs-select-item
                      :value="9"
                      :text="emotionLabel(9)"
                      class="w-full"
                    />
                  </vs-select>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                >
                  <vs-button
                    color="danger"
                    type="border"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-top: 18px"
                    @click="
                      () => {
                        delimiterActive.values.splice(index, 1);
                      }
                    "
                    v-if="
                      delimiterActive.values.length > 2 &&
                        delimiterActive.values.length - 1 != index
                    "
                  ></vs-button>
                  <vs-button
                    color="success"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    style="margin-top: 18px"
                    @click="
                      () => {
                        delimiterActive.values.push(['', 0]);
                      }
                    "
                    v-if="delimiterActive.values.length - 1 == index"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </div>
            <vs-divider />
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-save"
              style="margin: auto; width: 150px !important"
              @click="onSaveDelimiterClick()"
            ></vs-button>
          </vs-tab>
        </vs-tabs>
      </vs-card>
      <!-- <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />-->
      <vs-card v-if="areas.length > 0" style="margin-top: 10px">
        <div slot="header">
          <h3>{{ $l.get("research-form.labels-field") }}</h3>
        </div>
        <vs-tabs v-model="labelsTabIndex">
          <vs-tab :label="$l.get('dictionary.all')">
            <vs-table :data="labels">
              <template slot="thead">
                <vs-th>{{ $l.get("dictionary.label") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.wejoy-area") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.wejoy-emotion") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
                <vs-th>{{ $l.get("dictionary.remove") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="idx" v-for="(tr, idx) in data">
                  <vs-td :data="tr.label">{{ tr.label }}</vs-td>
                  <vs-td :data="tr.areaName">{{ tr.areaName }}</vs-td>
                  <vs-td :data="tr.emotionName">{{ tr.emotionName }}</vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="edit"
                      @click="onEditLabelClick(idx, tr)"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <vs-button
                      color="primary"
                      type="border"
                      icon="delete"
                      @click="onRemoveLabelClick(idx)"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
          <vs-tab :label="labelFormTab">
            <vs-input
              :label-placeholder="$l.get('dictionary.label', 'capitalize')"
              :danger="isLabelNameInvalid"
              :danger-text="$l.get('errors.input-required', 'capitalize')"
              v-model="labelActive.label"
            />
            <br />
            <vs-select
              v-model="labelActive.area"
              class="w-full select-large"
              :label="$l.get('research-details.area-field')"
            >
              <vs-select-item
                :key="index"
                :value="area.id"
                :text="area.title"
                v-for="(area, index) in areas"
                class="w-full"
              />
            </vs-select>
            <br />
            <vs-select
              v-model="labelActive.emotion"
              class="w-full select-large"
              :label="$l.get('research-details.emotion-field')"
            >
              <vs-select-item
                :value="1"
                :text="emotionLabel(1)"
                class="w-full"
              />
              <vs-select-item
                :value="2"
                :text="emotionLabel(2)"
                class="w-full"
              />
              <vs-select-item
                :value="3"
                :text="emotionLabel(3)"
                class="w-full"
              />
              <vs-select-item
                :value="4"
                :text="emotionLabel(4)"
                class="w-full"
              />
              <vs-select-item
                :value="5"
                :text="emotionLabel(5)"
                class="w-full"
              />
              <vs-select-item
                :value="6"
                :text="emotionLabel(6)"
                class="w-full"
              />
              <vs-select-item
                :value="7"
                :text="emotionLabel(7)"
                class="w-full"
              />
              <vs-select-item
                :value="8"
                :text="emotionLabel(8)"
                class="w-full"
              />
              <vs-select-item
                :value="9"
                :text="emotionLabel(9)"
                class="w-full"
              />
            </vs-select>
            <vs-divider />
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-save"
              style="margin: auto; width: 150px !important"
              @click="onSaveLabelClick()"
            ></vs-button>
          </vs-tab>
        </vs-tabs>
      </vs-card>
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";

export default {
  name: "research-form",
  props: ["research", "onSubmit", "diagnostics"],
  components: { VueSimpleSuggest },
  data() {
    return {
      delimitersTabIndex: 0,
      delimiterEditIdx: null,
      labelsTabIndex: 0,
      labelEditIdx: null,
      isNameInvalid: false,
      isSamplesRequiredInvalid: false,
      isDelimiterNameInvalid: false,
      isLabelNameInvalid: false,
      isTitleEnUsInvalid: false,
      isTitlePtBrInvalid: false,
      isTitleEsEsInvalid: false,
      delimiterActive: {},
      delimiters: [],
      labelActive: {},
      labels: [],
      slider1: [0, 0],
      slider2: 70,
      slider3: 80,
      gateways: {
        research: null,
        session: null,
        figure: null
      },
      areas: []
    };
  },
  beforeMount() {
    this.gateways.session = this.$injector.get("SessionGateway");
    this.gateways.research = this.$injector.get("ResearchGateway");
    this.gateways.figure = this.$injector.get("FigureGateway");
    this.clearDelimiter();
    this.clearLabel();
  },
  mounted() {
    this.slider1 = [this.research.rMin * 100, this.research.rMax * 100];
    this.slider2 = this.research.globalRateMin * 100;
    this.slider3 = this.research.p2Max * 100;
    if (this.research.p3Data.delimiters) {
      this.delimiters = this.research.p3Data.delimiters;
    }
    if (this.research.labels) {
      this.labels = this.research.labels;
    }
    if (this.research.dataSource) {
      this.onDiagnosisSelect({
        id: this.research.dataSource.value,
        title: this.research.dataSource.label
      });
    }
  },
  watch: {
    delimitersTabIndex: function() {
      if (this.delimitersTabIndex == 0) {
        this.delimiterEditIdx = null;
        this.clearDelimiter();
      }
    },
    labelsTabIndex: function() {
      if (this.labelsTabIndex == 0) {
        this.labelEditIdx = null;
        this.clearLabel();
      }
    }
  },
  computed: {
    delimiterFormTab() {
      if (this.delimiterEditIdx == null) {
        return this.$l.get("dictionary.new");
      } else {
        return this.$l.get("dictionary.edit");
      }
    },
    labelFormTab() {
      if (this.labelEditIdx == null) {
        return this.$l.get("dictionary.new");
      } else {
        return this.$l.get("dictionary.edit");
      }
    },
    uploadAddress() {
      return `${this.gateways.figure.URL}/`;
    },
    uploadHeaders() {
      const session = this.gateways.session.get();
      return session.tokens.getHeader();
    }
  },
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.research.name.length < 1;
      this.isTitleEnUsInvalid = !this.research.titleEnUs;
      this.isTitlePtBrInvalid = !this.research.titlePtBr;
      this.isTitleEsEsInvalid = !this.research.titleEsEs;
      this.isSamplesRequiredInvalid = !this.research.samplesRequired;
      if (
        this.isNameInvalid ||
        this.isTitleEnUsInvalid ||
        this.isTitlePtBrInvalid ||
        this.isTitleEsEsInvalid ||
        this.isSamplesRequiredInvalid
      ) {
        return;
      } else {
        this.research.rMin = this.slider1[0] / 100;
        this.research.rMax = this.slider1[1] / 100;
        this.research.globalRateMin = this.slider2 / 100;
        this.research.p2_max = this.slider3 / 100;
        this.research.p3_data = {
          delimiters: this.delimiters
        };
        this.research.labels = this.labels;
        this.research.figure = this.research.figure.id;
        // console.log(this.research);
        this.onSubmit();
      }
    },
    onDiagnosisSelect(e) {
      this.research.dataSource = {
        type: 2, // wejoy fixed
        label: e.title,
        value: e.id
      };
      const session = this.gateways.session.get();
      this.gateways.research
        .listDiagnosticsAreas(session.tokens, e.id)
        .then(areas => {
          this.areas = areas;
        });
    },
    onEditDelimiterClick(idx, delimiter) {
      this.delimiterEditIdx = idx;
      this.delimiterActive = JSON.parse(JSON.stringify(delimiter));
      this.delimitersTabIndex = 1;
    },
    onRemoveDelimiterClick(idx) {
      this.delimiters.splice(idx, 1);
    },
    onSaveDelimiterClick() {
      this.isDelimiterNameInvalid = this.delimiterActive.name.length < 1;
      if (this.isDelimiterNameInvalid) {
        return;
      }
      if (this.delimiterActive.values.length < 2) {
        return;
      }
      for (let value of this.delimiterActive.values) {
        if (!value[0] || value[1] < 1) {
          return;
        }
      }
      if (this.delimiterEditIdx == null) {
        this.delimiters.push(this.delimiterActive);
      } else {
        this.delimiters[this.delimiterEditIdx] = this.delimiterActive;
      }
      this.clearDelimiter();
      this.delimitersTabIndex = 0;
    },
    clearDelimiter() {
      this.delimiterActive = {
        name: "",
        values: [
          ["", 1],
          ["", 1]
        ]
      };
    },
    emotionLabel(value) {
      if (value == 1) {
        return this.$l.get("research-details.desire-field");
      } else if (value == 2) {
        return this.$l.get("research-details.satisfaction-field");
      } else if (value == 3) {
        return this.$l.get("research-details.frustration-field");
      } else if (value == 4) {
        return this.$l.get("research-details.dissatisfaction-field");
      } else if (value == 5) {
        return this.$l.get("research-details.active-field");
      } else if (value == 6) {
        return this.$l.get("research-details.passive-field");
      } else if (value == 7) {
        return this.$l.get("research-details.pleasant-field");
      } else if (value == 8) {
        return this.$l.get("research-details.unpleasant-field");
      } else if (value == 9) {
        return this.$l.get("research-details.neutral-field");
      } else return "";
    },
    onEditLabelClick(idx, label) {
      this.labelEditIdx = idx;
      this.labelActive = JSON.parse(JSON.stringify(label));
      this.labelsTabIndex = 1;
    },
    onRemoveLabelClick(idx) {
      this.labels.splice(idx, 1);
    },
    onSaveLabelClick() {
      this.isLabelNameInvalid = this.labelActive.label.length < 1;
      if (this.isLabelNameInvalid) {
        return;
      }
      if (!this.labelActive.area || this.labelActive.emotion < 1) {
        return;
      }

      this.labelActive.emotionName = this.emotionLabel(
        this.labelActive.emotion
      );
      for (const area of this.areas) {
        if (area.id == this.labelActive.area) {
          this.labelActive.areaName = area.title;
          break;
        }
      }

      if (this.labelEditIdx == null) {
        this.labels.push(this.labelActive);
      } else {
        this.labels[this.labelEditIdx] = this.labelActive;
      }
      this.clearLabel();
      this.labelsTabIndex = 0;
    },
    clearLabel() {
      this.labelActive = {
        label: "",
        area: "",
        areaName: "",
        emotion: 1,
        emotionName: ""
      };
    },
    onRemoveDataSourceClick() {
      this.research.dataSource = null;
    },
    addDelimiterValue() {
      this.delimiterActive.values.push(["", 0]);
    },
    removeDelimiterValue(index) {
      this.delimiterActive.values.splice(index, 1);
    },
    onUploadSuccess(data) {
      let figure = JSON.parse(data.target.responseText);
      this.research.figure = figure;
      this.$forceUpdate();
      //   this.interactors.uploadRawDataSuccess.handle(rawData);
    },
    onUploadFail(data) {
      return;
    },
    onRemoveFigureClick() {
      this.research.figure = null;
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
@import "@/application/assets/scss/vuexy/extraComponents/autocomplete.scss";

.research-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.research-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.research-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.research-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.research-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

.research-form .con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.research-form .con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.research-form .con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.research-form .con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.research-form .con-img-upload .img-upload {
  display: none;
}
.research-form .con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.research-form .con-img-upload .disabled-upload {
  height: 4px !important;
}

// .research-form .vs-input {
//   margin: 15px;
// }
</style>
